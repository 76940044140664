import React from "react"
import PageTemplate from '../components/pageTemplate'
import ProductDisplay from '../components/product-display'
import { graphql } from 'gatsby'

export default ({data}) => (
  <PageTemplate title="Our Yoghurts" img={data.fileName}>
    <div className="text-center">
      <h5>Raybek Foods Greek Yoghurt has been proven to be a taste like no other!</h5>
      <p>Thick and creamy yet elegant and smooth, this satisfying combination rises above your average yoghurt.</p>
      <p>Raybek Foods Greek Yoghurt is your daily health kick being gluten free and 96% fat free with a selection of divine flavours including:</p>
    </div>
    <ProductDisplay products={data.yoghurts}/>
  </PageTemplate>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "banner-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yoghurts: allPrismicProducts(filter: {data: {product_type: {eq: "yoghurt"}}}) {
      edges {
        node {
          ...ProductInfo
        }
      }
    }
  }
`
